//
// quilljs-editor.scss
//
 
.ql-container {
    font-family: $font-family-base;
    border-bottom-left-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;

    &.ql-snow {
        border-color: $input-border-color;
    }
}

.ql-bubble {
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
}

.ql-toolbar {
    font-family: $font-family-base !important;
    border-top-left-radius: $input-border-radius;
    border-top-right-radius: $input-border-radius;
    span {
        outline: none !important;
        color: $dropdown-link-color;

        &:hover {
            color: $primary !important;
        }
    }

    &.ql-snow {
        border-color: $input-border-color;

        .ql-picker.ql-expanded {
            .ql-picker-label {
                border-color: transparent;
            }
        }
        .ql-formats {
            margin-right: 15px;
            margin-left: 0;
        }
    }
}

.ql-snow {
    .ql-stroke,
    .ql-script,
    .ql-strike svg {
        stroke: $dropdown-link-color;
    }
    .ql-fill {
        fill: $dropdown-link-color;
    }
}

.ql-snow {
    .ql-picker-options {
        background-color: $dropdown-bg;
        border-color: $dropdown-border-color !important;
    }
}


.ql-editor {
    text-align: left;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    position: absolute;
    margin-top: -9px;
    right: 0;
    left: auto;
    top: 50%;
    width: 18px;
}

.ql-editor {
    ol, ul {
        li:not(.ql-direction-rtl) {
            padding-left: 1.5em;
            padding-right: 0;
        }
    }
    li:not(.ql-direction-rtl) {
        &:before {
            margin-left: -1.5em;
            margin-right: 0.3em;
            text-align: right;
        }
    }
}

.ql-editor {
    ol, ul {
        padding-left: 1.5em;
        padding-right: 0;
    }
}

// react-draft-wysiwyg
.rdw-editor-wrapper {
        border-color: $input-border-color !important;

    .rdw-editor-toolbar {
        background: transparent !important;
        border-bottom: 1px solid $input-border-color !important; 
        border: none;
        
        .rdw-option-wrapper, .rdw-block-dropdown, .rdw-fontsize-dropdown, .rdw-fontfamily-dropdown, .rdw-dropdown-optionwrapper{
            background: $input-bg !important;
            border-color: transparent !important;
            &:hover, &.rdw-option-active {
                box-shadow: none !important;
            }

            img{
                fill: $dark !important;
            }

            .rdw-dropdownoption-highlighted, .rdw-dropdownoption-active{
                background: $dropdown-link-active-bg !important;
            }
        }

         .rdw-option-wrapper {
             background: transparent !important;
         }
    }

    .rdw-editor-main{
        border-color: $input-border-color !important;
    }
}

// simplemde editor

.EasyMDEContainer{
    .CodeMirror {
        background: $input-bg !important;
        border-color: $input-border-color !important;
    }
    .editor-toolbar{
        border-color: $input-border-color !important;

        i.separator{    
            border-color: $input-border-color !important;
        }

        button {
            color: $dark !important;
            &:hover {
                background: tint-color($gray-300, 2%) !important;
                border-color: transparent !important;
            }
        }
    }

    .CodeMirror-lines {
        .CodeMirror-cursors {
            .CodeMirror-cursor{
                border-color: $dark;
            }
        }
        .CodeMirror-code{
            .CodeMirror-line {
                color: $dark;
                .cm-null {
                    background: transparent !important;
                    color:  $input-color !important;
                }
            }
        }
    }
}