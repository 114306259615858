//
// components-demo.scss
//

// Demo Only
.button-list {
  margin-left: -8px;
  margin-bottom: -12px;

  .btn {
    margin-bottom: 12px;
    margin-left: 8px;
  }
}

// Grid
.grid-structure {
  .grid-container {
    background-color: $table-striped-bg;
    margin-bottom: 10px;
    font-size: 0.8rem;
    font-weight: $font-weight-semibold;
    padding: 10px 20px;
  }
}

// Icon demo ( Demo only )
.icons-list-demo {
  div.col-sm-6 {
    cursor: pointer;
    line-height: 45px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    p {
      margin-bottom: 0;
      line-height: inherit;
    }
  }
  i {
    text-align: center;
    vertical-align: middle;
    font-size: 22px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin-right: 12px;
    color: $text-muted;
    border-radius: 3px;
    display: inline-block;
    transition: all 0.2s;
  }
  .col-md-4 {
    border-radius: 3px;
    background-clip: padding-box;
    margin-bottom: 10px;
    &:hover,
    &:hover i {
      color: $primary;
    }
  }
}

.icons-list {
  .icon-item {
    padding: 15px 0;
    color: $gray-600;
    svg {
      margin-right: 10px;
    }

    span {
      line-height: 30px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.dashed-border-box {
  border: 2px dashed;
  border-radius: 40px;
  min-height: 350px;
  display: flex;
  align-items: center;
}

.plus-image {
  margin-left: -40px;
  margin-bottom: -40px;
}

.form-label-icon {
  margin-right: 8px;
  width: 20px;
}

.form-label-text {
  color: #fff;

  .left-spacing {
    margin-left: 40px;
  }
}

.form-button-column {
  button {
    margin-right: 10px;
  }
}

.create-row-box {
  padding: 24px;
  border: 2px dashed;
  border-radius: 20px;
  display: flex;
  align-items: center;
  background-color: #36404a;
}

.create-row-button {
  display: flex;
  justify-content: end;
}

.table-data-header-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// Scrollspy

.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: 0.5rem;
  overflow: auto;
}

.terms-list-item {
  cursor: pointer;
  color: #fff;
}

.terms-list-selected {
  color: #fa5934;
  font-weight: 500;
  border-color: #d84c2c;
  border-top-width: 1px !important;
}

.transaction-data {
  width: 120px;
  word-break: break-all;
}

.home-page-card {
  cursor: pointer;
  border-radius: 34px;
  background: rgba(32, 38, 44, 0.5);
}

.home-page-node-card {
  border-radius: 34px;
  background: rgba(32, 38, 44, 0.5);
}

.home-page-image {
  display: flex;
  justify-content: center;
}

.verify-code-input {
  margin-right: 0.5rem;
  margin-left: 0.5rem !important;
  color: #94a0ad;
  background-color: #3b454f;
  border-color: #47525e;
  width: 2em !important;
  height: 2em !important;
  font-size: 20px;
  border-style: solid;
}

.no-stake-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 8%;
  button {
    margin-top: 1rem;
  }
}

.blockchain-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100px;
}

.blockchain-card h5 {
  font-family: "Lato", sans-serif;
  font-size: 0.8rem;
  letter-spacing: 0px;
  text-transform: uppercase;
}

.proposal-review-box {
  display: flex;
  flex-direction: column;
}

.proposal-review-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.proposal-review-group {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.proposal-review-item {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
}

.proposal-review-item.proposal-review-deployment-name {
  justify-content: center;
}

.proposal-review-text {
  display: inline-flex;
  flex-direction: column;
}

.proposal-review-label {
  line-height: 100%;
  font-size: 1rem;
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.3px;
}

.proposal-review-value {
  padding-top: 10px;
  line-height: 100%;
  font-size: 1rem;
  color: #fa5934;
  font-family: "Lato", sans-serif;
  letter-spacing: 1px;
}

.proposal-review-deployment-name .proposal-review-label {
  font-size: 1rem;
}

.proposal-review-label.data-job-label {
  text-transform: none;
  letter-spacing: 0.3px;
}

.crypto-brand-icon {
  width: 15%;
  max-height: 200px;
}

.btn-cta,
.form-check-input:checked {
  background-color: #d84c2c !important;
  border-color: #d84c2c !important;
}

.text-muted {
  color: #acbfd2 !important;
}

.text-primary {
  color: #fa5934 !important;
}

.crypto-brand-select {
  display: flex;
  justify-content: end;
  padding-right: 4px;
}
